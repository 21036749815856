<template>
    <div>
        <FormGroup :label="label" :disabled="noValidate" :rules="rules" class="mb-0">
            <template #input="{ state }">
                <b-form-file v-model="fileUpload" placeholder="Vælg fil..." :disabled="disabled" :state="state" @change="uploadFile" />
            </template>
        </FormGroup>

        <b-row>
            <b-col>
                <div v-if="file" class="position-relative d-inline-block ma-5 pr-40">
                    <b-link :href="file" target="_blank">{{fileName}}</b-link>

                    <b-button variant="gradient-danger" class="btn-icon rounded-circle position-absolute pa-4"
                              style="bottom: -3px; right: 0;" size="sm" @click="deleteFile(file)">
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            value: null,
            url: String,
            deleteUrl: String,
            label: {
                type: String,
                default: "Fil"
            },
            disabled: Boolean,
            noValidate: Boolean,
            rules: [String, Object],
        },
        data: () => ({
            fileUpload: null
        }),
        computed: {
            file: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            },
            fileName() {
                return this.file.replace(/^.*[\\\/]/, '')
            }
        },
        created() { },
        mounted() { },
        methods: {
            uploadFile(e) {
                var formData = new FormData()
                var imagefiles = e.target.files
                if (imagefiles && imagefiles.length > 0) {
                    imagefiles.forEach(x => {
                        formData.append("file", x)
                    })
                    
                    this.$http.post(this.url, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(x => {
                        this.fileUpload = null
                        e.target.value = ""

                        this.$emit("uploaded", x.data)
                    })
                }
            },
            deleteFile(item) {
                this.$http.post(this.deleteUrl, { path: item }).then(x => {
                    this.$emit("deleted")
                })
            }
        },
    }
</script>