<template>
    <flat-pickr v-model="date" class="date-picker" :config="dateConfig" :class="{ 'is-invalid': !state && state != null }" @input="dateChanged"></flat-pickr>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css';
    import { Danish } from "flatpickr/dist/l10n/da.js";

    export default {
        components: { flatPickr },
        props: {
            value: null,
            state: null
        },
        data: () => ({
            dateConfig: {
                wrap: true,
                altFormat: 'j. M, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                locale: Danish,
                weekNumbers: false
            }
        }),
        computed: {
            date: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        },
        created() { },
        mounted() { },
        methods: {
            dateChanged(val) {
                if (val == "") {
                    this.date = null
                }
            }
        },
    }
</script>

<style lang="scss" >
    .date-picker {
        &[readonly] {
            background-color: #ffffff!important;
        }

        &.is-invalid + input.form-control {
            border-color: #ea5455;
        }
    }

</style>