import Vue from 'vue'

// axios
import axios from 'axios'

//axios.defaults.timeout = 10000

axios.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 401) {
        //console.log('unauthorized, logging out ...')
        //window.location = "/logout"
    }
    return Promise.reject(error.response)
})

Vue.prototype.$http = axios

export default axios
