<template>
    <div>
        <div class="vue-map-container w-100" :style="'min-height: ' + height + 'px'">
            <div ref="vue-map" class="vue-map"></div>
            <div class="vue-map-hidden">
                <slot></slot>
            </div>
            <slot name="visible"></slot>
        </div>
    </div>
</template>

<script>
const recyclePrefix = '__gmc__'

export default {
	components: {},
	props: {
        value: null,
        center: {
            required: true,
            type: Object,
        },
        startLocation: Object,
        zoom: {
            required: false,
            type: Number,
        },
        heading: {
            type: Number,
        },
        mapTypeId: {
            type: String
        },
        tilt: {
            type: Number
        },
        height: {
            type: String,
            default: "500"
        },
        options: {
            type: Object,
            default () { return {} }
        }
    },
	data: () => ({
        $mapObject: null,
        markers: [],
        startMarker: null
    }),
	computed: {
        tasks: {
            get() {
                if (!this.value) {
                    return []
                }
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    watch: {
        zoom (zoom) {
            if (this.$mapObject) {
                this.$mapObject.setZoom(zoom)
            }
        },
        tasks: {
            handler(val) {
                this.addMarkers(val)
            },
            deep: true
        },
        startLocation(val) {
            if (this.startMarker) {
                this.startMarker.setMap(null)
            }
            this.setStartMarker(val)
        }
    },
	created() {},
	mounted() {
        this.$gmapApiPromise().then(() => {
            // getting the DOM element where to create the map
            const element = this.$refs['vue-map']

            // creating the map
            var options = {
                zoom: this.zoom,
                center: this.center,
                mapTypeId: this.mapTypeId,
                ...this.options,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                ]
            }

            const recycleKey = this.getRecycleKey()

            if (this.options.recycle && window[recycleKey]) {
                element.appendChild(window[recycleKey].div)
                this.$mapObject = window[recycleKey].map
                this.$mapObject.setOptions(options)
            } else {
                this.$mapObject = new google.maps.Map(element, options)
                window[recycleKey] = { map: this.$mapObject }
            }

            this.$mapObject.addListener('zoom_changed', () => {
                this.$emit('zoom_changed', this.$mapObject.getZoom())
            })

            this.$mapObject.addListener('bounds_changed', () => {
                this.$emit('bounds_changed', this.$mapObject.getBounds())
            })

            this.$mapObject.addListener('idle', () => {
                this.$emit('idle', this.$mapObject.getBounds())
            })

            if (this.startLocation) {
                this.setStartMarker(this.startLocation)
            }

            if (this.tasks.length > 0) {
                this.addMarkers(this.tasks)
            }

            return this.$mapObject
        })
        .catch(error => {

        })
    },
    beforeDestroy () {
        const recycleKey = this.getRecycleKey()
        if (window[recycleKey]) {
            window[recycleKey].div = this.$mapObject.getDiv()
        }
    },
	methods: {
        getRecycleKey () {
            return this.options.recycle ? recyclePrefix + this.options.recycle : recyclePrefix
        },
        setStartMarker(location) {
            if (location) {
                var icon = {
                    url: require('@/assets/images/icons/home-marker.png'),
                    scaledSize: new google.maps.Size(25, 34),
                }

                var marker = new google.maps.Marker({
                    position: location,
                    map: this.$mapObject,
                    icon: icon,
                    zIndex: 9999
                });

                this.startMarker = marker
            } else {
                this.startMarker.setMap(null)
                this.startMarker = null
            }
        },
        search() {
            var geocoder = new google.maps.Geocoder();

            geocoder.geocode({ address: this.city, region: "DK" }, (results, status) => {
                if (status == 'OK') {
                    this.$mapObject.setCenter(results[0].geometry.location);

                    var bounds = results[0].geometry.bounds
                    var northEast = bounds.getNorthEast()
                    var southWest = bounds.getSouthWest()

                    var path = [
                        { lat: northEast.lat(), lng: southWest.lng() },
                        { lat: northEast.lat(), lng: northEast.lng() },
                        { lat: southWest.lat(), lng: northEast.lng() },
                        { lat: southWest.lat(), lng: southWest.lng() },
                    ];

                    this.addPath(path)
                }
            });


        },
        addMarkers(obj) {

            this.markers.forEach(marker => {
                marker.setMap(null)
            })
            this.markers = []

            obj.forEach(task => {

                // require('@/assets/images/icons/home-marker.png')
                // require('@/assets/images/icons/task-marker.png')

                var icon = {
                    url: require('@/assets/images/icons/task-marker.png'),
                    scaledSize: new google.maps.Size(25, 34),
                }

                var planned = task.planning.length > 0;
                var zIndex = 10;

                if (planned) {
                    icon.url = require('@/assets/images/icons/planned2-marker.png')
                    zIndex = 1
                }

                var marker = new google.maps.Marker({
                    position: { lng: task.location.coordinates[0], lat: task.location.coordinates[1] },
                    map: this.$mapObject,
                    title: task.address,
                    id: task.id,
                    icon: icon,
                    zIndex: zIndex
                });

                if (!planned) {
                    marker.addListener("click", () => {
                        this.$emit('click_marker', marker.id)
                    });
                }

                marker.addListener("mouseover", () => {
                    this.$emit('mouseover_marker', marker.id)
                });
                marker.addListener("mouseout", () => {
                    this.$emit('mouseout_marker', marker.id)
                });

                this.markers.push(marker)

                //marker.setMap(this.$mapObject);

            })
        },
        highlightMarker(id, hover) {
            var marker = this.markers.find(x => x.id == id)
            if (marker) {
                var icon = {
                    url: require('@/assets/images/icons/highlight-marker.png'),
                    scaledSize: new google.maps.Size(25, 34),
                }
                var zIndex = 99999
                if (!hover) {
                    icon.url = require('@/assets/images/icons/task-marker.png')
                    zIndex = 10
                }
                marker.setIcon(icon)
                marker.setZIndex(zIndex)
            }
        }
    },
};
</script>


<style lang="css">
    .vue-map-container {
        position: relative;
    }
    .vue-map-container .vue-map {
        left: 0; right: 0; top: 0; bottom: 0;
        position: absolute;
    }
    .vue-map-hidden {
        display: none;
    }
</style>