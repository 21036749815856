const subjects = [
    { label: "Test Module", value: "TestModule" },
]

const fields = {
    "TestModule": [

    ]
}

const routes = [

]

const navigation = [

]

export default {
    routes,
    subjects,
    fields,
    navigation
}