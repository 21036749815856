<template>
    <div>

        <div class="app-calendar overflow-hidden border">
            <div v-if="$can('read', 'Client')" class="row no-gutters">

                <!-- Calendar -->
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0">
                            <full-calendar ref="refCalendar"
                                           :options="calendarOptions"
                                           class="full-calendar">
                                <template v-slot:eventContent="arg">
                                    <span class="fc-event-title d-block">{{ arg.event.title }}</span>
                                </template>
                            </full-calendar>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 bg-white pa-10">
                    <button class="btn btn-success btn-block mb-15" @click="newTask">
                        <feather-icon icon="PlusIcon" class="mr-5" />
                        Opret opgave
                    </button>

                    <div v-for="item in tasks" :key="item.plan.id" class="calendar-items">
                        <div class="card-body">
                            <h4 class="card-title">
                                {{$moment(item.plan.dateTime).format("HH:mm")}} - {{$moment(item.plan.dateTime).add(item.task.estimatedTime, 'minutes').format("HH:mm")}}
                                <span class="order-number">#{{item.task.no}}</span>
                            </h4>
                            <h6 class="card-subtitle">
                                {{item.task.description}}
                                <small :class="'d-block ' + $store.state.taskStatus[item.task.status].class">{{$store.state.taskStatus[item.task.status].text}}</small>
                            </h6>
                            <hr class="m-0" />
                            <p class="card-subtitle">
                                <span>
                                    {{item.type.name}}
                                    <small v-if="item.task.faultCode" class="text-danger">
                                        <br />
                                        {{$store.state.errorCodes.find(x => x.value == item.task.faultCode).label}}
                                    </small>
                                    <small v-if="item.task.resolutionCode" class="text-success">
                                        <br />
                                        {{$store.state.solutionCodes.find(x => x.value == item.task.resolutionCode).label}}
                                    </small>
                                </span>


                                {{item.task.serviceArticle.name}}
                                <br />
                                {{item.task.serviceArticle.address}}
                                <br />
                                {{item.task.serviceArticle.zipCode}} {{item.task.serviceArticle.city}}
                            </p>

                            <b-link :to="'/dayplan/' + item.task.id" class="stretched-link"></b-link>
                        </div>
                    </div>

                </div>


            </div>
        </div>

    </div>

</template>


<script>
    import FullCalendar from '@fullcalendar/vue'

    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import listPlugin from "@fullcalendar/list";
    import interactionPlugin from "@fullcalendar/interaction";
    import daLocale from '@fullcalendar/core/locales/da';
    import momentTimezonePlugin from '@fullcalendar/moment-timezone';

    import NewTaskModal from "@/components/_createTaskModal.vue"


    import CardStatisticOrderChart from './demo/CardStatisticOrderChart.vue'
    import CardStatisticProfitChart from './demo/CardStatisticProfitChart.vue'
    import CardStatisticsGroup from './demo/CardStatisticsGroup.vue'

    import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
    import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
    import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
    import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'

    export default {
        components: { FullCalendar },
        data: self => ({
            calendarOptions: {
                timeZone: "Europe/Berlin",
                plugins: [dayGridPlugin, interactionPlugin, listPlugin, momentTimezonePlugin],
                locale: daLocale,
                initialView: "dayGridMonth",
                headerToolbar: {
                    start: "prev,next, title",
                    end: "dayGridMonth,listMonth",
                },
                events: (info, successCallback) => {
                    // If there's no info => Don't make useless API call
                    if (!info) return;

                    self.$http.post("/api/dashboard/calendar", { start: info.start, end: info.end }).then(x => {
                        successCallback(x.data)
                    }).catch(() => {

                    })
                },
                eventClassNames(obj) {
                    var colorName = "primary" //calendarsColor[obj.event.extendedProps.shippingMethod];
                    var status = obj.event.extendedProps.status

                    if (status == 0) {
                        colorName = "primary"
                    }
                    if (status == 10) {
                        colorName = "info"
                    }
                    if (status == 20) {
                        colorName = "warning"
                    }
                    if (status == 50) {
                        colorName = "success"
                    }
                    if (status == 99) {
                        colorName = "danger"
                    }

                    return [
                        // Background Color
                        `bg-light-${colorName}`,
                    ];
                },
                eventClick({ event: clickedEvent }) {
                    self.getTasks(clickedEvent)
                },
                editable: false,
                eventResizableFromStart: false,
                dragScroll: true,
                dayMaxEvents: 10,
                navLinks: false,
                rerenderDelay: 0,
                contentHeight: 500,
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false
                }
            },
            tasks: []
        }),
        computed: {
        },
        created() { },
        mounted() {},
        methods: {
            getTasks(event) {
                this.$http.get("/api/dashboard/calendar/tasks/" + event.id).then(x => {
                    this.tasks = x.data
                }).catch(() => {

                })
            },

            newTask() {
                let calendarApi = this.$refs.refCalendar.getApi()

                this.$modal(NewTaskModal, { item: this.item }, {
                    success: x => {
                        this.$toaster({
                            title: "Opgave oprettet!",
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        })
                        calendarApi.refetchEvents()
                    }
                })
            }

        },
    }
</script>

<style lang="scss">
    @import "@core/scss/vue/apps/calendar.scss";

    .fc-event {

        > span {
            display: block;
            width: 100%;
        }

        .fc-event-title {
        }

        .fc-event-time {
            font-weight: normal;
        }

        .fc-event-time > span {
            float: right;
            display: block;
        }
    }

    .calendar-items {
        position: relative;
        margin-bottom: 0px;
        border: 1px solid #ddd;

        .card-body {
            padding: 0px;
        }

        .card-title {
            background: #f8f8f8;
            padding: 8px 14px;
            font-size: 1em;
            font-weight: bold;
            margin: 0;

            span {
                float: right;
            }
        }

        .card-subtitle {
            padding: 8px 14px;
            color: #6e6b7b;
            margin: 0;

            span {
                float: right;
                font-size: 0.9em;
            }
        }
    }
</style>