import Vue from 'vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon)

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
Vue.component("app-collapse", AppCollapse)

import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
Vue.component("app-collapse-item", AppCollapseItem)


import '@core/scss/vue/libs/vue-select.scss'
import vSelect from 'vue-select'
Vue.component("v-select", vSelect)


import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})
import maxValue from './libs/max_value'
extend('max_val', { ...maxValue, message: "{_field_} må maksimalt være {max} eller mindre" });

import da from 'vee-validate/dist/locale/da.json'
localize('da', da)
Vue.component("validate", ValidationProvider)
Vue.component("validate-form", ValidationObserver)


import draggable from 'vuedraggable'
Vue.component("draggable", draggable)


import Editor from '@tinymce/tinymce-vue'
Vue.component("editor", Editor)

import { VueAutosuggest } from 'vue-autosuggest'
Vue.component("vue-autosuggest", VueAutosuggest)


import FormGroup from '@/components/_formGroup.vue'
Vue.component("FormGroup", FormGroup)


import FieldGroup from '@/components/_fieldGroup.vue'
Vue.component("FieldGroup", FieldGroup)


import Images from '@/components/_images.vue'
Vue.component("Images", Images)


import ImageUpload from '@/components/_image.vue'
Vue.component("ImageUpload", ImageUpload)


import File from '@/components/_file.vue'
Vue.component("File", File)


import DatePicker from '@/components/_datePicker.vue'
Vue.component("DatePicker", DatePicker)