<template>
    <b-modal ref="newTaskModal" title="Opret opgave" @ok="save">
        <validate-form ref="form">

            <FormGroup label="Montør" rules="required">
                <template #input="{state, invalid}">
                    <v-select v-model="item.installerId" :options="installers" placeholder="Vælg montør" label="name" :filterable="false" :searchable="false" class="mr-10"
                              style="flex-grow: 1" :class="{ 'is-invalid': invalid }" :reduce="x => x.id">
                        <template slot="option" slot-scope="option">
                            {{ option.name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }}
                        </template>
                    </v-select>
                </template>
            </FormGroup>

            <FormGroup label="Dato" rules="required">
                <template #input="{state, invalid}">
                    <b-form-datepicker v-model="item.date"
                                       locale="da"
                                       start-weekday="1"
                                       hide-header
                                       label-help=""
                                       size="sm"
                                       :class="{ 'is-invalid': invalid }"
                                       :date-format-options="{ 'month': 'short', 'day': 'numeric', 'year': 'numeric' }"></b-form-datepicker>
                </template>
            </FormGroup>



            <FormGroup label="Opgavetype" rules="required">
                <template #input="{state, invalid}">
                    <v-select v-model="item.type" :options="taskTypes" placeholder="Vælg opgavetype" label="name" :filterable="false"
                              :class="{ 'is-invalid': invalid }" @search="fetchTaskTypes" @input="selectType">
                        <template slot="option" slot-scope="option">
                            {{ option.name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }}
                        </template>
                    </v-select>
                </template>
            </FormGroup>


            <FormGroup label="Kunde" rules="required">
                <template #input="{state, invalid}">
                    <v-select v-model="item.serviceArticle" :options="serviceArticles" placeholder="Find kunde" label="name" :filterable="false"
                              :class="{ 'is-invalid': invalid }" @search="fetchServiceArticles" @input="selectArticle">
                        <template slot="option" slot-scope="option">
                            {{ option.name }} {{ option.no }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }} {{ option.no }}
                        </template>
                    </v-select>
                </template>
            </FormGroup>


            <FormGroup label="Ønsket tidspunkt" rules="required">
                <template #input="{state, invalid}">
                    <v-select v-model="item.timeOfDay" :options="$store.state.timeOfDay" :class="{ 'is-invalid': invalid }" placeholder="Vælg tidspunkt" :reduce="x => x.value"></v-select>
                </template>
            </FormGroup>

            <template v-if="item.serviceArticle">
                <FormGroup label="Produkt" rules="required">
                    <template #input="{state, invalid}">
                        <v-select v-model="item.serviceArticle.productNo" :options="products" :class="{ 'is-invalid': invalid }" placeholder="Vælg produkt"
                                  :reduce="x => x.no" label="name" :filterable="false" @search="fetchProducts">
                            <template slot="option" slot-scope="option">
                                {{ option.name }} {{ option.no }}
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                {{ option.name }} {{ option.no }}
                            </template>
                        </v-select>
                    </template>
                </FormGroup>
            </template>

            <FormGroup label="Fejlkode" rules="required" class="">
                <template #input="{state, invalid}">
                    <v-select v-model="item.faultCode" :options="$store.state.errorCodes" placeholder="Vælg fejlkode" label="label" :filterable="false"
                              :class="{ 'is-invalid': invalid }" @search="fetchTaskTypes" :reduce="x => x.value">
                        <template slot="option" slot-scope="option">
                            {{ option.label }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.label }}
                        </template>
                    </v-select>
                </template>
            </FormGroup>


            <FormGroup label="Intern" rules="">
                <template #input="{state}">
                    <b-form-textarea v-model="item.internText" rows="3" :state="state"></b-form-textarea>
                </template>
            </FormGroup>


            <FormGroup label="Fejl" rules="required">
                <template #input="{state}">
                    <b-form-textarea v-model="item.faultText" rows="3" :state="state"></b-form-textarea>
                </template>
            </FormGroup>



        </validate-form>


        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="secondary" @click="cancel()">
                Fortryd
            </b-button>

            <b-button size="md" variant="success" @click="ok()" :disabled="loading">
                <div v-if="loading" class="d-flex">
                    <b-spinner small class="mr-7"></b-spinner>
                    <span>Gemmer...</span>
                </div>
                <span v-else>Opret opgave</span>
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        components: {},
        props: {},
        data: () => ({
            item: {
                id: null,
                typeId: null,
                type: null,
                estimatedTime: null,
                serviceArticle: null,
                serviceArticleNo: null,
                productNo: null,
                fieldValues: [],
                documentationValues: [],
                intern: [],
                error: [],
                solution: [],
                images: []
            },

            installers: [],
            products: [],
            taskTypes: [],
            serviceArticles: [],

            loading: false
        }),
        computed: {},
        created() {
            this.$_debounceTimer = null

            this.getInstallers()
            this.fetchTaskTypes("", () => { })

        },
        mounted() { },
        methods: {
            save(e) {
                e.preventDefault()

                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true
                        this.$http.post("/api/planning/tasks/create", this.item).then(x => {
                            this.loading = false

                            this.$emit("success", x.data)

                            this.$refs.newTaskModal.hide()
                        }).catch(x => {

                        })
                    }
                })
            },

            selectType(val) {
                if (val) {
                    this.item.typeId = val.id

                    if (this.item.estimatedTime == null) {
                        this.$set(this.item, "estimatedTime", val.estimatedTime)
                    }
                } else {
                    this.item.typeId = null
                }
            },
            selectArticle(val) {
                if (val) {
                    this.item.description = val.description

                    this.fetchProducts(val.productNo, x => {
                        if (!x) {
                            if (this.products.length == 0) {
                                //this.item.serviceArticle.productNo = null
                            }
                        }
                    })
                }
            },

            getInstallers() {
                this.$http.get("/api/planning/installers").then(x => {
                    this.installers = x.data
                })
            },

            fetchTaskTypes(search, loading) {
                loading(true)

                this.$http.get("/api/task-types", {
                    params: {
                        search: search
                    }
                }).then(x => {
                    this.taskTypes = x.data
                    loading(false)
                })
            },
            fetchServiceArticles(search, loading) {
                if (search.length) {
                    this.debounce(() => {
                        loading(true)

                        this.$http.get("/api/service-articles", {
                            params: {
                                search: search
                            }
                        }).then(x => {
                            this.serviceArticles = x.data
                            loading(false)
                        })
                    }, 400)
                }
            },
            fetchProducts(search, loading) {
                if (search.length) {
                    loading(true)

                    this.$http.get("/api/documentation/products", {
                        params: {
                            search: search
                        }
                    }).then(x => {
                        this.products = x.data
                        loading(false)
                    })
                }
            },

            debounce(method, timer) {
                if (this.$_debounceTimer !== null) {
                    clearTimeout(this.$_debounceTimer)
                }
                this.$_debounceTimer = setTimeout(() => {
                    method()
                }, timer)
            }

        },
    }
</script>