import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ability from '@/libs/acl/ability'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInit: false,
        user: null,
        syncing: false,

        userTypes: [
            //{ label: "Ingen", value: 0 },
            { label: "Montør", value: 10 },
            { label: "Planlægger", value: 20 },
        ],

        weekDays: [
            { label: "Mandag", value: 1 },
            { label: "Tirsdag", value: 2 },
            { label: "Onsdag", value: 3 },
            { label: "Torsdag", value: 4 },
            { label: "Fredag", value: 5 },
            { label: "Lørdag", value: 6 },
            { label: "Søndag", value: 7 },
        ],

        fieldTypes: [
            { label: "Tekst", value: 0 },
            { label: "Titel", value: 5 },
            { label: "Tal", value: 10 },
            //{ label: "Fil-upload", value: 20 },
            { label: "Lang tekst", value: 30 },
            //{ label: "RichText", value: 40 }
            { label: "Udregning", value: 50 },
            { label: "Udregning fra felt", value: 55 },
            { label: "Udregning af to felter", value: 56 },
            { label: "Forbrug", value: 60 },
            { label: "Forbrug fra felt", value: 65 },
            { label: "Information", value: 70 },
            { label: "Afkrydsning", value: 80 },
            { label: "Makro", value: 100 },
        ],

        timeOfDay: [
            { label: "Formiddag", value: 0 },
            { label: "Eftermiddag", value: 10 },
        ],

        errorCodes: [
            { label: "Driftsstop", value: "10" },
            { label: "Periodisk stop", value: "15" },
            { label: "Manglende varme", value: "20" },
            { label: "Manglende varmt vand", value: "25" },
            { label: "Komponentfejl", value: "30" },
            { label: "Kontrol", value: "35" },
            { label: "Kører på elback-up/driftsstop", value: "5" },
            { label: "Assistance", value: "82" },
            { label: "Rådgivning", value: "84" },
            { label: "Opstart/indregulering + serviceaftale", value: "91" },
            { label: "Opstart/indregulering", value: "92" },
            { label: "Eftersyn", value: "94" }
        ],

        solutionCodes: [
            { label: "Udskiftet - 10", value: "10" },
            { label: "Renset - 20", value: "20" },
            { label: "Justeret - 30", value: "30" },
            { label: "Kontrolleret - 40", value: "40" },
            { label: "Fejlinstalleret - 70", value: "70" },
            { label: "Assisteret - 82", value: "82" },
            { label: "Rådgivet - 84", value: "84" },
            { label: "Opstart og Indregulering - 92", value: "92" },
            { label: "Eftersyn - 94", value: "94" },
            { label: "Service aftale - 95", value: "95" },
            { label: "Fejl installeret - 96", value: "96" },
        ],

        taskStatus: {
            0: { text: "Klar", class: "text-primary" },
            10: { text: "På vej", class: "text-info" },
            20: { text: "Startet", class: "text-warning" },
            50: { text: "Udført", class: "text-success" },
            99: { text: "Afbrudt", class: "text-danger" },
            100: { text: "Slettet", class: "text-danger" }
        },


        contractGroups: {
            "121": { text: "Gas - Fortløbende - Små", value: "121" },
            "122": { text: "Gas - Fortløbende - Store", value: "122" },
            "131": { text: "Gas - 3-årig - Små", value: "131" },
            "132": { text: "Gas - 3-årig - Store", value: "132" },
            "141": { text: "Gas - 4-årig - Små", value: "141" },
            "142": { text: "Gas - 4-årig - Store", value: "142" },
            "151": { text: "Gas - 5-årig - Små", value: "151" },
            "152": { text: "Gas - 5-årig - Store", value: "152" },
            "154": { text: "Serviceaftale  sølv store gaskedler 38-110kw>15år", value: "154" },
            "156": { text: "Serviceaftale  sølv store gaskedler 38-110kw<15år", value: "156" },
            "157": { text: "Serviceaftale GULD 4-10 år", value: "157" },
            "158": { text: "Serviceaftale SØLV u/15 år", value: "158" },
            "159": { text: "Serviceaftale SØLV o/15 år", value: "159" },
            "171": { text: "Gas - 2-årig - Eftersyn", value: "171" },
            "191": { text: "Gas - 10-årig - Små", value: "191" },
            "192": { text: "Gas - 10-årig - Store", value: "192" },
            "391": { text: "Bio - 15-årig", value: "391" },
            "521": { text: "Varmepumper - Fortløbende", value: "521" },
            "522": { text: "Varmepumper - Abonnement", value: "522" },
            "550": { text: "Varmepumpe 12 års garantiaftale", value: "550" },
        },

    },

    actions: {
        INIT_USER({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                axios.get("/api/user").then(x => {
                    commit("INIT_USER", x.data)
                    ability.update(x.data.acl)
                    resolve()
                }).catch(x => {
                    commit("INIT_USER", null)
                    resolve()
                })
            })
        },
        LOGIN({ commit, dispatch }, data) {
            commit("INIT_USER", data)
        },

        SYNCING({ commit, dispatch }, data) {
            commit("SYNCING", data)
        }
    },

    mutations: {
        INIT_USER(state, val) {
            state.userInit = true
            state.user = val
        },

        SYNCING(state, val) {
            state.syncing = val
        },
    },


    modules: {
        app,
        appConfig,
        verticalMenu,
    },
    strict: process.env.DEV,
})
