const isNullOrUndefined = (...values) => {
    return values.every(value => {
        return value === null || value === undefined;
    });
};

const validate = (value, {max}) => {
    if (isNullOrUndefined(value) || value === '') {
      return false;
    }
  
    if (Array.isArray(value)) {
      return value.length > 0 && value.every(val => validate(val, {max}));
    }

    if (isNaN(value)) {
        value = value.replace(/\./g, '')
        value = value.replace(/,/g, '.')
    }
  
    return Number(value) <= max;
  };

  const params = [
    {
      name: 'max',
      cast(value) {
        return value;
      }
    }
  ];
  
  export {
    validate, params
  };
  
  export default {
    validate, params
  };