<template>
    <div id="app" class="h-100" :class="[skinClasses]">
        <component :is="layout">
            <router-view :key="$route.name + ($route.params.id || '')" />
        </component>

        <!--<b-modal id="refreshContent" title="Nyt indhold" :visible="updateExists" ok-title="Genindlæs siden" centered cancel-title="Luk"
                 @ok="refreshApp">
            <p class="my-4">Siden er blevet opdateret!</p>
        </b-modal>-->

    </div>
</template>

<script>
    // This will be populated in `beforeCreate` hook
    import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
    import { provideToast } from 'vue-toastification/composition'
    import { watch } from '@vue/composition-api'
    import useAppConfig from '@core/app-config/useAppConfig'

    import { useWindowSize, useCssVar } from '@vueuse/core'

    import store from '@/store'

    const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
    const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

    export default {
        components: {
            // Layouts
            LayoutVertical,
            LayoutFull,
        },
        data: () => ({
            refreshing: false,
            registration: null,
            updateExists: false,
        }),
        // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
        // Currently, router.currentRoute is not reactive and doesn't trigger any change
        computed: {
            layout() {
                if (!this.$route.name) {
                    return "layout-full"
                }

                if (this.$route.meta.layout !== 'full') return 'layout-vertical'
                return "layout-full"
            }
        },
        beforeCreate() {
            // Set colors in theme
            const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
            }
        },
        setup() {
            const { skin, skinClasses } = useAppConfig()

            // If skin is dark when initialized => Add class to body
            if (skin.value === 'dark') document.body.classList.add('dark-layout')

            // Provide toast for Composition API usage
            // This for those apps/components which uses composition API
            // Demos will still use Options API for ease
            //provideToast({
            //    hideProgressBar: true,
            //    closeOnClick: false,
            //    closeButton: false,
            //    icon: false,
            //    timeout: 3000,
            //    transition: 'Vue-Toastification__fade',
            //})

            // Set Window Width in store
            store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
            const { width: windowWidth } = useWindowSize()
            watch(windowWidth, val => {
                store.commit('app/UPDATE_WINDOW_WIDTH', val)
            })

            return {
                skinClasses,
            }
        },
        created() {
            //document.addEventListener('swUpdated', this.updateAvailable, { once: true })

            //navigator.serviceWorker.addEventListener('controllerchange', () => {
            //    if (this.refreshing) return
            //    this.refreshing = true
            //    // Here the actual reload of the page occurs
            //    window.location.reload()
            //})
        },
        mounted() {

        },
        methods: {
            updateAvailable(e) {
                this.registration = e.detail
                //this.updateExists = true

                this.$bvModal.msgBoxConfirm('Tryk på "Genindlæs side" for at opdatere indholdet. Indtastet information som ikke er blevet gemt vil gå tabt!', {
                    title: 'Nyt indhold!',
                    size: 'sm',
                    //buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Genindlæs side',
                    cancelTitle: 'Luk',
                    //footerClass: 'p-2',
                    //hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        this.refreshApp()
                    }
                }).catch(err => {
                    // An error occurred
                })
            },
            refreshApp() {
                this.updateExists = false
            //    // Make sure we only send a 'skip waiting' message if the SW is waiting
            //    if (!this.registration || !this.registration.waiting) return
            //    // send message to SW to skip the waiting and activate the new SW
            //    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            },
        }
    }
</script>
