import router from '@/router'

import testModule from "./TestModule"

const routes = [
    ...testModule.routes
]

for (var i = 0; i < routes.length; i++) {
    var route = routes[i]

    router.addRoute(route)
}


//{
//    path: '/stoholm-products',
//        name: 'stoholm-products',
//            component: Products,
//                meta: {
//        pageTitle: 'Produkter',
//            breadcrumb: [
//                {
//                    text: 'Produkter',
//                    active: true,
//                },
//            ],
//                resource: 'Product',
//                    action: 'read',
//        },
//},