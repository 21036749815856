<template>
    <b-form-group :label="noLabel ? null : label">
        <validate #default="{ errors }" :name="label" :rules="rules" :disabled="disabled" :vid="vid">
            <slot name="input" :state="getState(errors)" :invalid="isInvalid(errors)">
                <b-form-input :lazy="lazy" v-model="editorValue" :type="type" :state="getState(errors)" 
                    :placeholder="placeholder" :readonly="readonly" :min="min" :max="max" />
            </slot>
            <small v-if="!disabled" class="text-danger">{{ errors[0] }}</small>
        </validate>
    </b-form-group>
</template>

<script>
    export default {
        components: {},
        props: {
            value: null,
            label: String,
            rules: [String, Object],
            placeholder: String,
            disabled: Boolean,
            lazy: Boolean,
            type: {
                type: String,
                default: "text"
            },
            vid: String,
            noLabel: Boolean,
            readonly: Boolean,
            max: [String,Number],
            min: [String,Number],
        },
        data: () => ({
        }),
        computed: {
            editorValue: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            }
        },
        created() {

        },
        mounted() { },
        methods: {
            getState(errors) {
                return errors.length > 0 && !this.disabled ? false : null
            },
            isInvalid(errors) {
                return errors.length > 0 && !this.disabled
            }
        },
    }
</script>