import Vue from 'vue'
import Toast from '@/components/_toast.vue'

var mixins = {
    methods: {
        $successToast(title) {
            this.$toast({
                component: Toast,
                props: {
                    title: title,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                },
            })
        },
        $errorToast(title) {
            this.$toast({
                component: Toast,
                props: {
                    title: title,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                },
            })
        },
        $toaster(props) {
            this.$toast({
                component: Toast,
                props: props,
            })
        },
        $modal(c, propsData, events) {
            let vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
            let parent
            let ModalComponent = vm.extend(c)

            let component = new ModalComponent({
                store: this.$store,
                parent,
                el: document.createElement('div'),
                propsData
            })
            
            for (const key in events) {
                if (Object.hasOwnProperty.call(events, key)) {
                    const event = events[key];
                    component.$on(key, event)
                }
            }

            component.$children[0].show()

            // this.$modal(
            //     CommentModal, 
            //     { item: this.item }, 
            //     {
            //         update(obj) {

            //         }
            //     }
            // )
        }
    }
}

Vue.mixin(mixins)