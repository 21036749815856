var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"newTaskModal",attrs:{"title":"Opret opgave"},on:{"ok":_vm.save},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
var hide = ref.hide;
return [_c('b-button',{attrs:{"size":"md","variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Fortryd ")]),_c('b-button',{attrs:{"size":"md","variant":"success","disabled":_vm.loading},on:{"click":function($event){return ok()}}},[(_vm.loading)?_c('div',{staticClass:"d-flex"},[_c('b-spinner',{staticClass:"mr-7",attrs:{"small":""}}),_c('span',[_vm._v("Gemmer...")])],1):_c('span',[_vm._v("Opret opgave")])])]}}])},[_c('validate-form',{ref:"form"},[_c('FormGroup',{attrs:{"label":"Montør","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('v-select',{staticClass:"mr-10",class:{ 'is-invalid': invalid },staticStyle:{"flex-grow":"1"},attrs:{"options":_vm.installers,"placeholder":"Vælg montør","label":"name","filterable":false,"searchable":false,"reduce":function (x) { return x.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.item.installerId),callback:function ($$v) {_vm.$set(_vm.item, "installerId", $$v)},expression:"item.installerId"}})]}}])}),_c('FormGroup',{attrs:{"label":"Dato","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('b-form-datepicker',{class:{ 'is-invalid': invalid },attrs:{"locale":"da","start-weekday":"1","hide-header":"","label-help":"","size":"sm","date-format-options":{ 'month': 'short', 'day': 'numeric', 'year': 'numeric' }},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})]}}])}),_c('FormGroup',{attrs:{"label":"Opgavetype","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('v-select',{class:{ 'is-invalid': invalid },attrs:{"options":_vm.taskTypes,"placeholder":"Vælg opgavetype","label":"name","filterable":false},on:{"search":_vm.fetchTaskTypes,"input":_vm.selectType},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})]}}])}),_c('FormGroup',{attrs:{"label":"Kunde","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('v-select',{class:{ 'is-invalid': invalid },attrs:{"options":_vm.serviceArticles,"placeholder":"Find kunde","label":"name","filterable":false},on:{"search":_vm.fetchServiceArticles,"input":_vm.selectArticle},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" "+_vm._s(option.no)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" "+_vm._s(option.no)+" ")]}}],null,true),model:{value:(_vm.item.serviceArticle),callback:function ($$v) {_vm.$set(_vm.item, "serviceArticle", $$v)},expression:"item.serviceArticle"}})]}}])}),_c('FormGroup',{attrs:{"label":"Ønsket tidspunkt","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('v-select',{class:{ 'is-invalid': invalid },attrs:{"options":_vm.$store.state.timeOfDay,"placeholder":"Vælg tidspunkt","reduce":function (x) { return x.value; }},model:{value:(_vm.item.timeOfDay),callback:function ($$v) {_vm.$set(_vm.item, "timeOfDay", $$v)},expression:"item.timeOfDay"}})]}}])}),(_vm.item.serviceArticle)?[_c('FormGroup',{attrs:{"label":"Produkt","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('v-select',{class:{ 'is-invalid': invalid },attrs:{"options":_vm.products,"placeholder":"Vælg produkt","reduce":function (x) { return x.no; },"label":"name","filterable":false},on:{"search":_vm.fetchProducts},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" "+_vm._s(option.no)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" "+_vm._s(option.no)+" ")]}}],null,true),model:{value:(_vm.item.serviceArticle.productNo),callback:function ($$v) {_vm.$set(_vm.item.serviceArticle, "productNo", $$v)},expression:"item.serviceArticle.productNo"}})]}}],null,false,1898602403)})]:_vm._e(),_c('FormGroup',{attrs:{"label":"Fejlkode","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
var invalid = ref.invalid;
return [_c('v-select',{class:{ 'is-invalid': invalid },attrs:{"options":_vm.$store.state.errorCodes,"placeholder":"Vælg fejlkode","label":"label","filterable":false,"reduce":function (x) { return x.value; }},on:{"search":_vm.fetchTaskTypes},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" ")]}}],null,true),model:{value:(_vm.item.faultCode),callback:function ($$v) {_vm.$set(_vm.item, "faultCode", $$v)},expression:"item.faultCode"}})]}}])}),_c('FormGroup',{attrs:{"label":"Intern","rules":""},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
return [_c('b-form-textarea',{attrs:{"rows":"3","state":state},model:{value:(_vm.item.internText),callback:function ($$v) {_vm.$set(_vm.item, "internText", $$v)},expression:"item.internText"}})]}}])}),_c('FormGroup',{attrs:{"label":"Fejl","rules":"required"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var state = ref.state;
return [_c('b-form-textarea',{attrs:{"rows":"3","state":state},model:{value:(_vm.item.faultText),callback:function ($$v) {_vm.$set(_vm.item, "faultText", $$v)},expression:"item.faultText"}})]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }